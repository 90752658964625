import React from "react";

import styles from "./content-area-feature-list-schools.module.css";

const ContentAreaFeatureListSchools = ({}) => (
  <div className={styles.contentAreaWrapper}>
    <h2>KEYNOTES</h2>
    <ul>
      <li className={styles.alternateRow}>
        Band based Peri music service. Free to your school.
      </li>
      <li>Lessons delivered by professionals with all instruments provided</li>
      <li className={styles.alternateRow}>
        Support mental health and wellbeing through music
      </li>
      <li>
        Encourage more GCSE uptake by inspiring pupils to learn an instrument in
        KS3
      </li>
      <li className={styles.alternateRow}>
        Bursary, pupil premium and funded options to ensure everyone can get
        involved
      </li>
      <li>Flexible timetables to suit your school</li>
    </ul>
  </div>
);

export default ContentAreaFeatureListSchools;
