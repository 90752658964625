import React from "react";
import { Container, Grid } from "semantic-ui-react";

import LayoutHome from "../../components/layout/layout-home";
import SEO from "../../components/seo/seo";
import SecondaryNavBar from "../../components/secondary-nav-bar/secondary-nav-bar";
import HeadingImage from "../../components/heading-image";
import StyledContentImage from "../../components/styled-content-image";
import ContentAreaPrimary from "../../components/content-area-primary";
import ContentAreaFeatureListSchools from "../../components/content-area-feature-list-schools/content-area-feature-list-schools";
import ContactFormSecondaySchools from "../../components/contact-form-secondary-schools";
import Testimonial from "../../components/testimonial/testimonial";

const Page = () => (
  <LayoutHome>
    <SEO title="Rocksteady Secondary" keywords={[`rocksteady`]} />

    <Container>
      <SecondaryNavBar
        product="secondary"
        title="Rocksteady Secondary"
        links={[
          {
            name: "For Students / Parents",
            path: "/secondary/students-and-parents/",
            active: false
          },
          {
            name: "For Schools",
            path: "/secondary/schools/",
            active: true
          }
        ]}
        cta={{
          text: "Request more information",
          href: "#contact-form-secondary-schools"
        }}
      />
    </Container>
    <HeadingImage
      fileName="schools/banner_bg.jpg"
      h1="Rocksteady Secondary"
      h2="For Schools"
    />
    <Container>
      <Grid stackable columns={2}>
        <Grid.Column width={10}>
          <ContentAreaPrimary>
            <h2>INSPIRE THE NEXT WAVE OF MUSICANS AT YOUR SCHOOL</h2>

            <p>
              The Rocksteady Secondary program is designed to inspire new
              musicians, teach the fundamentals of playing in a band and quickly
              progress to writing, recording and performing their own songs.
              Pupils in your school who never thought of themselves as musical
              will see themselves in a new light.
            </p>

            <StyledContentImage filename="schools/photo_1.jpg" />

            <h2>RAISE THE PROFILE OF YOUR DEPARTMENT</h2>

            <p>
              By removing some of the traditional barriers associated with
              learning an instrument: sight reading, scale exercises and formal
              exams, music becomes accessible to more of your pupils. Great news
              for performance opportunities, the profile of your department and
              even your GCSE uptake.
            </p>

            <h2>NO LONGER JUST FOR KIDS</h2>

            <p>
              The Rocksteady Secondary program has been specifically designed
              with KS3 in mind. The program blends the accessibility and
              relevance of our primary model with a stronger focus on
              creativity, writing and recording music.
            </p>

            <StyledContentImage
              filename="schools/photo_2.jpg"
              floated="left"
              clipStyle="left"
            />

            <h2>WE MANAGE EVERYTHING</h2>

            <p>
              We’ll provide your school with a highly trained and well supported
              peri teacher who specialises in band skills and is passionate
              about getting more children playing music. It’s free for your
              school and costs significantly less for parents than private music
              tuition. We handle all the enrolment and payment admin too.
            </p>

            <h2>THE ADDED BENEFITS OF BEING A MUSICIAN</h2>

            <p>
              Music is proven to help young people to reduce anxiety, gain
              control of emotions and focus attention. Teenage years can be
              difficult for a number of reasons and giving your pupils a
              creative outlet plays an important part in supporting mental
              health and wellbeing. Our bursary, pupil premium and external
              funding options mean that everyone has the opportunity to benefit.
            </p>

            <h2>EVERY PUPIL IN YOUR SCHOOL WILL PLAY</h2>

            <p>
              Experience tells us that young people are much more likely to
              start playing an instrument once they have had a go. That’s why we
              commit to a band experience session for every child in your
              school, free of charge.
            </p>

            <h1>
              To find out more or to discuss availability in your area please
              get in touch using the form below or call 0330 113 0411
            </h1>
            <ContactFormSecondaySchools />
          </ContentAreaPrimary>
        </Grid.Column>
        <Grid.Column width={6}>
          <ContentAreaFeatureListSchools />
          <StyledContentImage
            filename="schools/photo_3.jpg"
            floated="right"
            clipStyle="left"
            width="90%"
            marginBottom="42px"
          />
          <Testimonial
            text="Rocksteady have added an entirely new dimension to music at West Exe School. Their use of exciting and engaging workshops has captured the interest of even some of our most disinterested students. The impact is not limited to their musical interests. I can think of at least 3 students at KS3 who are truly thriving within school, entirely revitalised by the impact of their Rocksteady sessions."
            author="Alex Kirkbride – Deputy Headteacher West Exe School"
          />
        </Grid.Column>
      </Grid>
    </Container>
  </LayoutHome>
);

export default Page;
