import React from "react";

import styles from "./testimonial.module.css";
import { Icon } from "semantic-ui-react";

const Testimonial = ({ text, author }) => (
  <div className={styles.contentAreaWrapper}>
    <div className={styles.contentAreaInnerWrapper}>
      <p>
        <Icon name="quote left" />
      </p>
      <p className={styles.quote}>{text}</p>
      <p style={{ textAlign: "right" }}>
        <Icon name="quote right" />
      </p>
      <p className={styles.author}>{author}</p>
    </div>
  </div>
);

export default Testimonial;
