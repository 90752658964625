import { Link } from "gatsby";
import React from "react";
import { Container, Menu, Icon } from "semantic-ui-react";

import secondaryNavBarStyles from "./secondary-nav-bar.module.css";

const Links = ({ links }) =>
  links.map(link => (
    <Menu.Item
      active={link.active}
      className={
        link.active
          ? secondaryNavBarStyles.secondaryMenuItemActive
          : secondaryNavBarStyles.secondaryMenuItem
      }
      key={`link-key-${link.name}`}
    >
      <Link
        to={link.path}
        className={
          link.active
            ? secondaryNavBarStyles.secondaryMenuItemLinkActive
            : secondaryNavBarStyles.secondaryMenuItemLink
        }
      >
        {link.name}
      </Link>
    </Menu.Item>
  ));

const CTA = ({ cta, buttonColor, buttonTextColor }) => {
  if (!cta) {
    return null;
  }
  return (
    <a
      href={cta.href}
      style={{
        backgroundColor: buttonColor,
        color: buttonTextColor
      }}
      className={secondaryNavBarStyles.ctaButton}
    >
      {cta.text} <Icon name="right arrow" />
    </a>
  );
};

const SecondaryNavBar = ({ product, links, cta }) => {
  let bg, buttonColor;
  let buttonTextColor = "#333";

  switch (product) {
    case "primary":
      bg = "#0081B0";
      buttonColor = "#FBBB00";
      break;
    case "secondary":
      bg = "#D51E39";
      buttonColor = "#FBBB00";
      break;
    case "voices":
      bg = "#FBBB00";
      buttonColor = "#D51E39";
      buttonTextColor = "#fff";
      break;
    case "camps":
      bg = "#212563";
      buttonColor = "#D51E39";
      buttonTextColor = "#fff";
      break;
    case "foundation":
      bg = "#76397c";
      buttonColor = "#D51E39";
      buttonTextColor = "#fff";
      break;

    case "info":
      bg = "#000";
      buttonColor = "#0081B0";
      buttonTextColor = "#fff";
      break;
    default:
      break;
  }

  return (
    <>
      <CTA
        buttonColor={buttonColor}
        cta={cta}
        buttonTextColor={buttonTextColor}
      />
      <Menu
        borderless
        inverted
        fixed="top"
        className={secondaryNavBarStyles.menu}
        style={{
          backgroundColor: bg
        }}
      >
        <Container>
          <Menu.Menu position="left" className={secondaryNavBarStyles.menuLeft}>
            <Links links={links} />
          </Menu.Menu>
        </Container>
      </Menu>
    </>
  );
};

export default SecondaryNavBar;
