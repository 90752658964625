import React from "react";
import PropTypes from "prop-types";

import Layout from "../layout/layout";

const LayoutHome = ({ children }) => <Layout>{children}</Layout>;

LayoutHome.propTypes = {
  children: PropTypes.node.isRequired
};

export default LayoutHome;
