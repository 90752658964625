import React from "react";
import { Button, Form, Icon } from "semantic-ui-react";

import styles from "./contact-form-secondary-schools.module.css";

const ContactFormSecondarySchools = ({}) => (
  <div className={styles.contentAreaWrapper}>
    <a className={styles.anchor} id="contact-form-secondary-schools" />
    <div className={styles.contentAreaInnerWrapper}>
      <h2>REQUEST MORE INFORMATION</h2>
      <p>Register your interest and we'll get back to you</p>
      <Form
        action="https://formspree.io/ben.millier@rocksteadymusicschool.com"
        method="POST"
      >
        <Form.Group widths="equal">
          <Form.Field>
            <label>Your Name</label>
            <input name="name" placeholder="Your Name" required />
          </Form.Field>
          <Form.Field>
            <label>Email</label>
            <input name="email" placeholder="Email" required type="email" />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>School Name</label>
            <input name="school" placeholder="School" required />
          </Form.Field>
          <Form.Field>
            <label>School Postcode</label>
            <input name="postcode" placeholder="Postcode" required />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Phone</label>
            <input name="phone" placeholder="Phone" required />
          </Form.Field>
          <Form.Field>
            <label>Your Role</label>
            <input name="role" placeholder="Role" required />
          </Form.Field>
        </Form.Group>
        <Form.TextArea label="Your Message" rows={7} />

        <Button type="submit" className={styles.submitButton} floated="right">
          Send Request <Icon name="right arrow" />
        </Button>
      </Form>
    </div>
  </div>
);

export default ContactFormSecondarySchools;
